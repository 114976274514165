<template>
    <div ref="container" class="flex flex-wrap">
        <div :class="'w-' + item.column" v-for="(item, n) in data" :key="n">
            <div class="my-2" :class="[(n + 1) % item.column === 0 ? '' : n !== 0 ? 'mr-2' : 'mr-2']">

                <b-field v-if="item.type.startsWith('input')" v-bind="fieldProps(item)">
                  <template #label>
                    {{ item.overrideLabel }}<sup v-if="isRequired(item)" class="has-text-danger">*</sup>
                  </template>
                  <div v-if="item.type == 'inputdate'" class="flex border border-solid border-bold p-3 bg-white rounded">
                    <b-icon class="text-primary self-center mr-4" icon="calendar-range" custom-size="mdi-24px"></b-icon>
                    <datepicker format="dd/MM/yyyy" :name="item.fieldToSave" class="w-full" v-model="values[item.fieldToSave]"></datepicker>
                  </div>
                  <div v-else-if="item.type == 'inputmoney'"><money-input  :name="item.fieldToSave" :placeholder="item.placeHolder" v-model="values[item.fieldToSave]" @keydown.native="touch(item.fieldToSave)" @blur="touch(item.fieldToSave)" :currency="user.CurrencyObj" :show-symbol="item.showSymbol"></money-input></div>
                  <div v-else>
                    <div v-if="item.type === 'inputtext'">
                      <b-input v-if="item.validationNumber" type="number" :name="item.fieldToSave" v-model="values[item.fieldToSave]" :placeholder="item.placeHolder" :controls="false" controls-position="left"></b-input>
                      <b-input v-else :name="item.fieldToSave" :placeholder="item.placeHolder" :type="item.type.replace('input', '')" v-model="values[item.fieldToSave]" @keydown.native="touch(item.fieldToSave)"></b-input>
                      <div v-if="item.option && item.option.length > 0" class="flex overflow-auto w-full mt-2">
                        <div v-for="(item, index) in item.option" :key="index" class="m-4 cursor-pointer hover:shadow-md">
                          <span class="border border-solid border-gray-500 rounded-lg p-2" @click="setValueInputText(item.Name)">{{ item.Name }}</span>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <b-input :name="item.fieldToSave" :placeholder="item.placeHolder" :type="item.type.replace('input', '')" v-model="values[item.fieldToSave]" @keydown.native="touch(item.fieldToSave)" @blur="touch(item.fieldToSave)" step="any" :password-reveal="item.type === 'inputpassword'" :maxlength="item.maxLength" :onkeypress="item.maxLength && `if(this.value.length==${item.maxLength}) return false;`"></b-input>
                    </div>
                  </div>
                </b-field>
                <b-field class="w-full" v-else-if="item.type === 'combobox'" v-bind="fieldProps(item)">
                  <template #label>
                    {{ item.overrideLabel }}<sup v-if="isRequired(item)" class="has-text-danger">*</sup>
                  </template>
                    <b-select :name="item.fieldToSave" @click.native="onClick(item)" class="w-full" :placeholder="item.placeHolder" :value="values[item.fieldToSave] === undefined ? item.defaultValue : values[item.fieldToSave]" @blur="touch(item.fieldToSave)">
                        <option :value="item.Oid" v-for="(item, n) in item.source" :key="n">{{ item.Name }}</option>
                    </b-select>
                </b-field>
                <div v-else-if="item.type === 'button'">
                    <b-button type="is-dark" @click="onButtonClick(item)">{{ item.name.toUpperCase() }}</b-button>
                </div>
                <b-field v-else-if="item.type === 'checkbox'">
                  <template #label>
                    {{ item.overrideLabel }}<sup v-if="isRequired(item)" class="has-text-danger">*</sup>
                  </template>
                  <b-checkbox :name="item.fieldToSave" v-model="values[item.fieldToSave]" type="is-primary">{{item.overrideLabel}}</b-checkbox>
                </b-field>
                <b-field v-else-if="item.type === 'label'" :label="item.overrideLabel"></b-field>

                <!-- VAddress -->
                <div v-else-if="item.type === 'autocomplete'">
                <b-field v-bind="fieldProps(item)">
                  <template #label>
                    {{ item.overrideLabel }}<sup v-if="isRequired(item)" class="has-text-danger">*</sup>
                  </template>
                  <b-autocomplete
                      :value="values[item.fieldToSave] ? values[item.fieldToSave].Name : ''"
                      :data="autocompleteData"
                      field="Name"
                      :placeholder="item.placeHolder"
                      @select="o => values[item.fieldToSave] = o"
                      @typing="v => getAutocompleteData(item, v)"
                      @blur="touch(item.fieldToSave)">
                      <template #empty>No results found</template>
                  </b-autocomplete>
                </b-field>
                  <input type="hidden" :name="item.fieldToSave" :value="values[item.fieldToSave] ? values[item.fieldToSave].Oid : ''" />
                </div>
                <div v-else-if="item.type === 'popupoptions'">
                  <b-field v-bind="fieldProps(item)">
                  <template #label>
                    {{ item.overrideLabel }}<sup v-if="isRequired(item)" class="has-text-danger">*</sup>
                  </template>
                    <b-input type="text" :placeholder="item.placeHolder" :value="values[item.fieldToSave] ? values[item.fieldToSave].Name : ''" readonly="" @click.native="openModal(item.modal)" @blur="touch(item.fieldToSave)">
                      </b-input>
                  </b-field>
                  <input type="hidden" :name="item.fieldToSave" :value="values[item.fieldToSave] ? values[item.fieldToSave].Oid : ''" />
                  <vaddress-modal v-bind="modalProps(item.modal)" :submit-button="null" :close-button="null" @close="closeModal(item.modal)">
                      <div v-for="(o, j) in item.source" class="flex items-center px-2 py-4 border-b -mx-4" :key="j" @click="() => { values[item.fieldToSave] = o; closeModal(item.modal) }">
                        <vaddress-icon-image :value="o.Icon"></vaddress-icon-image>
                        <strong class="ml-2">{{o.Name}}</strong>
                      </div>
                  </vaddress-modal>
                </div>
                <div v-else-if="item.type === 'avatar'">
                  <div class="flex flex-col items-center p-4">
                    <img :src="values[item.fieldToSave] || defaultAvatarImage" width="80" height="80" />
                    <b-button type="is-text" @click="getFile(`inputavatar${item.fieldToSave}`)">Change</b-button>
                  </div>
                  <input :name="item.fieldToSave" :id="`inputavatar${item.fieldToSave}`" type="file" class="hidden" @change="e => handleAvatarChange(e, item.fieldToSave)" />
                </div>
                <vaddress-location-input v-else-if="item.type === 'vaddress.inputlocation'" :data="item.Data" v-bind="Object.keys(item.Data).reduce((p, f) => { p.value[f] = values[item.Data[f].fieldToSave]; p.fields[f] = fieldProps(item.Data[f]); return p }, { value: {}, fields: {} })" @input="(v, f) => $set(values, f, v)"></vaddress-location-input>
                <div v-else-if="item.type === 'gridoptions'">
                  <b-field v-bind="fieldProps(item)">
                    <template #label>
                    {{ item.overrideLabel }}<sup v-if="isRequired(item)" class="has-text-danger">*</sup>
                    </template>
                    <div :class="['grid', 'grid-cols-' + (item.mobileCol || 3), 'gap-4']">
                      <div v-for="(option, i) in item.options" :key="i" :class="['rounded border text-center cursor-pointer w-full flex justify-center', { 'border-gray-400': item.value !== values[item.fieldToSave], 'border-primary': option.Value === values[item.fieldToSave] }]" @click="values[item.fieldToSave] = option.Value">
                        <img :src="option.Img" style="height: 80px; width: auto" />
                      </div>
                      <input :name="item.fieldToSave" type="hidden" :value="values[item.fieldToSave]" />
                    </div>
                  </b-field>
                </div>
                <!-- End of VAddress -->
            </div>
        </div>
    </div>
</template>
<script>
import debounce from 'lodash/debounce'
import DefaultAvatar from '../../assets/defaultavatar.png'
import Datepicker from 'vuejs-datepicker'
import MoneyInput from '../vaddress/form/Money.vue'
export default {
    components: {
      Datepicker,
      VaddressLocationInput: () => import('@/components/vaddress/common/LocationInput'),
      VaddressModal: () => import('@/components/vaddress/common/Modal'),
      VaddressIconImage: () => import('@/components/vaddress/common/IconImage'),
      MoneyInput
    },
    props: {
        data: {
            type: Array
        }
        // errors: {
        //   type: Object,
        //   default: () => ({})
        // }
    },
    data () {
      return {
        date: new Date(),
        values: {},
        autocompleteData: [],
        touched: [],
        defaultAvatarImage: DefaultAvatar,
        setDefaultField: ''
      }
    },
    methods: {
        setDefault (e) {
          return this.setDefaultField
        },
        setValueInputText (e) {
          this.values.Title = e
        },
        async onClick (item) {
            if (!item.onClick) return
            const response = await this.$baseApi.get('/' + item.onClick.store)
            item.source = response
        },
        fieldProps (item) {
          if (item.validationParams && item.validationParams.includes('email')) {
            if (!this.values.UserName.includes('@') && this.values.UserName !== '') {
                return {
                  type: 'is-warning',
                  message: 'Sorry the field entered is not email'
                }
            }
          } else {
            /* if (!item.IsUseLabel) {
                if ((!this.fieldCondition && item.validationParams === 'required') || (!this.fieldCondition && item.validationParams === 'required|same:Password')) return false
                if (!this.fieldCondition && item.fieldToSave === 'UserName' && this.values.UserName === '') return false
                if (!this.fieldCondition && item.fieldToSave === 'Password' && this.values.Password === '') return false
            } */
          const { validateOn = 'blur' } = item
          const isError = this.errors[item.fieldToSave] && (validateOn === 'blur' ? this.touched.indexOf(item.fieldToSave) !== -1 : 1)
            return {
              type: { 'is-danger': isError },
              message: isError ? this.errors[item.fieldToSave] : ''
            }
          }
        },
        isRequired (item) {
            if (!item.validationParams) return false
            return item.validationParams.indexOf('required') !== -1
        },
        onButtonClick (item) {
            var data = this.getData('formData')
            var url = this.stringReader(item.url, data)
            this.$router.push(url)
        },
        getAutocompleteData: debounce(async function (item, name) {
          this.autocompleteData = await this.$baseApi.get(item.sourceURL + `?Name=${name}`)
        }, 500),
        selectAutocomplete (v) {
          console.log(v)
        },
        touch (n) {
          if (this.touched.indexOf(n) === -1) {
            setTimeout(() => this.touched.push(n), 100)
          }
        },
        getFile (id) {
          const elem = document.getElementById(id)
          if (elem) elem.click()
        },
        handleAvatarChange (e, name) {
          if (e.target.files && e.target.files[0]) {
            const reader = new FileReader()
            reader.onload = (e) => {
              this.values[name] = e.target.result
            }
            reader.readAsDataURL(e.target.files[0])
          }
        }
    },
    computed: {
      fieldCondition () {
        return this.$store.state.service.conditionNotif
      },
      validationRules () {
        if (!this.data) return
        return this.data.reduce((p, v) => {
          p[v.fieldToSave] = {
            label: v.overrideLabel,
            rule: v.validationParams,
            email: v.validationEmail,
            number: v.validationNumber,
            validateOn: v.validateOn || 'blur'
          }
          return p
        }, {})
      },
      errors () {
        if (!this.validationRules) return
        return Object.keys(this.validationRules).reduce((p, v) => {
          if (this.validationRules && this.validationRules[v].rule) {
            const validationRule = this.validationRules[v]
            const rules = validationRule.rule.split('|')
            p[v] = rules.reduce((pp, rule) => {
              if (pp) return pp
              if (rule === 'required') {
                if (this.values[v] === '' || typeof this.values[v] === 'undefined' || this.values[v] === null) pp = 'This field is required'
                if (validationRule.email && this.values[v] !== null) if (!this.values[v].includes('@')) pp = 'This field required email'
                if (validationRule.number && this.values[v] == null) pp = 'This field is number'
              } else if (rule.indexOf('same') !== -1) {
                const sameWith = rule.split(':')[1]
                if (this.values[v] !== this.values[sameWith]) {
                  const fieldLabel = validationRule.label
                  const sameWithLabel = this.data.find(d => d.fieldToSave === sameWith).overrideLabel
                  pp = `${fieldLabel} is not same with ${sameWithLabel}`
                }
              }
              return pp
            }, '')
          }
          return p
        }, {})
      }
    },
    watch: {
      data: {
        immediate: true,
        handler (v) {
          console.log('data', v)
          const setDefaultValues = (obj, f) => {
            if (!f.fieldToSave && f.Data) {
              return Object.values(f.Data).reduce(setDefaultValues, obj)
            }
            obj[f.fieldToSave] = f.default
            return obj
          }
          if (v) {
            this.values = {
              ...this.values,
              ...(Array.isArray(v) ? v : Object.values(v))
              .reduce(setDefaultValues, {})
            }
          }
        }
      },
      touched (v) {
        // console.log(v)
      },
      errors: {
        immediate: true,
        handler (v) {
          this.$emit('error', v)
        }
      }
    }
}
</script>
<style lang="scss" scoped>
    ::v-deep .select {
        width: 100%;
        select {
            width: 100%;
        }
    }
</style>
